import React from 'react';

const PrivacyHeader = () => {
  return (
    <>
      <section
        className='page-header position-relative overflow-hidden ptb-120 bg-dark'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-12'>
              <div className='text-center'>
                <h1 className='display-5 fw-bold'>Terms of Use</h1> 
                 
              <p className='text-start'> By accessing the website at https://www.igeeksng.com, you are 
                agreeing to be bound by these terms of service, all applicable 
                laws and regulations, and agree that you are responsible for
                 compliance with any applicable local laws. If you do not agree
                  with any of these terms, you are prohibited from using or accessing this site. 
                  The materials contained in this website are protected by applicable 
                  copyright and trademark law.</p>
            </div>
          </div>
          </div>
          <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5'></div>
        </div>
      </section>
    </>
  );
};

export default PrivacyHeader;
