import React from 'react';
import AboutPageHero from '../components/about/AboutPageHero';
import OurStory from '../components/about/OurStory';
import PageMeta from '../components/common/PageMeta';
import RequestForDemo from '../components/others/RequestForDemo';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const About = () => {
  return (
    <Layout>
         <PageMeta title=' About Us- iGeeksNG Solution - Software &amp; IT Solutions Provider' />
      <Navbar classOption='navbar-light' />
      <AboutPageHero />
      <OurStory />
      <RequestForDemo />
      <Footer footerLight />
    </Layout>
  );
};

export default About;
