import Routes from './routers/Routes';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';


const App = () => {
  return (
    <>
      <Routes />
      {/* Tawk chat integration */}
      <TawkMessengerReact
        propertyId="624ee1b3c72df874911dc53e"
       widgetId="1g02239o0"/>
    </>
  );
};

export default App;
