const TestimonialData = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/james.png',
    authorName: 'Mr.James Oyanna',
    authorTitle: 'CTO at Onesphere Solutions Ltd.',
    quoteTitle: 'Great job on our SASS application',
    authorQuote:
      'igeeksNG was responsible for building our enterprise application. It meets all our requirements.',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/dmh.png',
    authorName: 'DMH TOUCH',
    quoteTitle: 'Design a Booking website',
    authorQuote:
      'I reached out to them to design me a booking website.Competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/nape.png',
    authorName: 'NAPE',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'We have been using MPPTIMER for our conferences and short courses for about 3 years and the software has helped to organize and keep to time during presentation.',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/movebot.png',
    authorName: 'MoveBot',
    // authorTitle: 'Mr Thomas, Founder and CEO at Movebot',
    quoteTitle: 'Build a logistics management application',
    authorQuote:
      'iGeeksNG helped us to build a fully functional and responsive logisitics management application.',
  },
  {
    id: 5,
    authorImg: 'assets/img/testimonial/rccg.png',
    authorName: 'RCCG Covenant House Lafayette',
    // authorTitle: 'no title',
    quoteTitle: 'Iregister Kids Application',
    authorQuote:'iRegisterKids has really made checking In &amp; out kids much faster and easier,what we love most is the classroom TV application which updates kids list in real time.Such a great product from iGeeksNG.',
  },
];



export { TestimonialData};


// Clients Data



const ClientsData = [
  {
    id: 1,
    clientImg: 'assets/img/clients/faith-academy1.png' ,
  },
  {
    id: 2,
    clientImg: 'assets/img/clients/double-dee1.png',
  },
  {
    id: 3,
    clientImg: 'assets/img/clients/triumph1.png',
  },
  {
    id: 5,
    clientImg: 'assets/img/clients/nape.png',
  },
  {
    id: 6,
    clientImg:  'assets/img/clients/dmh.png',
  },
  {
    id: 7,
    clientImg: 'assets/img/clients/movebot-logo.png',
  },
  {
    id: 8,
    clientImg:'assets/img/clients/rccg-logo.png' ,
  },
];

export { ClientsData};      