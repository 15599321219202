import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                  <Link to='/'>
                    <img
                      src= { footerLight?  'assets/img/igeeksng-logo-new.png' : 'assets/img/igeeks-ng-white-logo-2.png' }
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    </Link>
                      <Link to='/'>
                    <img
                       src= { footerLight?  'assets/img/igeeksng-logo-new.png' : 'assets/img/igeeks-ng-white-logo-2.png' }
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                    </Link>
                  </div>
                </div>
				        <div className='footer-single-col'>

                <p>
                  Impossible is Nothing...
                  </p>
                  <h6>Office Address</h6>
                        <p>6137 Crawfordsville Road Suite F, #361 Speedway IN 46224, USA.</p>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3 style={{color:"#ffb116"}}>Company</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/'  className='text-decoration-none'>
                            Home
                          </Link>
                        </li> 
                        <li>
                          <Link to='/services' className='text-decoration-none'>
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            About Us
                          </Link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3 style={{color:"#ffb116"}}>Quick Links</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='privacy-policy'
                            className='text-decoration-none'
                          >
                            Privacy Policy
                          </Link>
                          
                        </li>
                        <li>
                          <Link
                            to='terms-of-use'
                            className='text-decoration-none'
                          >
                            Terms of Use
                          </Link>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3 style={{color:"#ffb116"}}>Support</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                         
                        <li>
                        <h6>Office Phone</h6> 
                        <a class="link-danger" href="tel:+13176401999">+1 (317)-640-1999</a> 
                        </li>
                        <li>
                        <h6>Email Address</h6>
                        <a href="mailto:info@igeeksng.com">info@igeeksng.com</a> 
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; {new Date().getFullYear()} iGeeksNG Solution LLC Rights Reserved.
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://twitter.com/igeeksng' rel="noreferrer" target="_blank">
                        <i className='fab fa-twitter'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/in/igeeksng-solution-llc' rel="noreferrer" target="_blank">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
