import React from 'react';

const ContactBox = () => {
  return (
    <>
      <section className='contact-promo ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span style={{color:"#ffb116"}} className='fa fa-location-arrow fa-3x'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Visit Us</h5>
                  <p> 
                    <strong> 6137 Crawfordsville Road Suite F, #361 Speedway IN 46224, USA.</strong> 
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span style={{color:"#ffb116"}} className='fad fa-envelope fa-3x'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Send Us an Email</h5>
                  <p> 
                    <strong>info@igeeksng.com </strong>
                     and you'll receive a reply within 24 hours
                  </p>
                </div>
                <a
                 style={{background:"#0b163f", color:"#fff"}}
                  href='mailto:info@igeeksng.com'
                  className='btn mt-auto'
                >
                  Email Us
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span style={{color:"#ffb116"}} className='fad fa-phone fa-3x'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Give us a call</h5>
                  <p>
                    Reach out to us via this call line{' '}
                    from <strong>monday to friday</strong>
                    <strong> 9am to 5pm.</strong>
                  </p>
                </div>
                <a href='tel:+13176401999' className='remove-link btn btn-link mt-auto'>
                +1 (317)-640-1999
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBox;
