import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgOne = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            
          <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/about-img.png'
                    alt=''
                    className='img-fluid'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              
              <div className='mt-4' data-aos='fade-up'>
                <SectionTitle
                  title='Application Development'
                  description="We develop software for today's businesses. We use current software engineering methods, indigenous platforms, deep industry understanding, and strategic collaboration networks to help enterprises generate maximum digital value and unleash an incomparable competitive advantage."
                />
              </div>
              
              <ul
                className='list-unstyled d-flex flex-wrap   mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                 <li>
                  <span className='d-block mb-4'>
                    <i style={{color:"#ffb116", fontSize:35}} className='fa-solid fa-engine'></i>
                  </span>
                  <h3 className='h5'>Enterprise Software Engineering</h3>
                  <p>
                  Modern business requires high level of corporate agility. Develop business-centric, personalized application portfolios with cutting-edge technologies using iGeeksNG's Smart Engineering platform, which uses current engineering concepts and low-code solutions to keep client application ahead of the curve. iGeeksNG has developed an eco-system that allows you to quickly design business-centric solutions and maintain the digital pace with our cloud-first, configuration-driven approach to application development and flexible architecture.
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i style={{color:"#ffb116", fontSize:35}} className='fa-solid fa-user-robot'></i>
                  </span>
                  <h3 className='h5'>Application Modernization</h3>
                  <p> 
                  Accelerate your digital transformation while ensuring predictable results with our expertise in Modernization Acceleration Platform, iGeeksNG Cloud Acceleration Platform, and enhanced third-party solutions, you can modernize applications, boost technical value, embrace cloud-based development, and expand your digital portfolio. Implement a platform-driven cloud migration strategy to make applications digitally ready while maintaining business agility and scalability. Using a completely automated, tools-based approach, modernize legacy programs and their ecosystem to a cost-effective contemporary platform. With automated evaluations and remediation, you may upgrade technology and transfer applications at a low cost.
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i style={{color:"#ffb116", fontSize:35}} className='fa-solid fa-list-check'></i>
                  </span>
                  <h3 className='h5'>Application Management</h3>
                  <p>Using intelligent automation and platform-based debt management technique to fund modernization activities, achieve hyper-industrialization of application administration and save up to 60% on operating costs. To maintain optimal application performance, industrialize application management with our AI and machine learning-based system, we reduce application debt, increase automation, and cut costs across the board. We use modern engineering techniques to assist businesses improve their agility, speed, and reliability.</p>
                </li>
                 
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgOne;
