import React from 'react';

const PageHeader = ({ title, desc, blogtags }) => {
  return (
    <>
        <section
          className='page-header position-relative overflow-hidden ptb-120 bg-dark'
          style={{
            background:
              "url('assets/img/page-header-bg.svg')no-repeat bottom left",
          }}
        >
          <div className='container'>
            <div
              className={`row ${
                blogtags ? 'justify-content-center text-center' : ''
              }`}
            >
              <div className='col-lg-8 col-md-12'>
                <h1 className='display-5 fw-bold'>{title}</h1>
                <p className='lead'>{desc}</p>
              </div>
            </div>
      
          </div>
        </section>
    </>
  );
};

export default PageHeader;
