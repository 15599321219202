import React from 'react';

const Review = ({ reqPage}) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap mt-5'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
              Start Your Project with Us
            </h1>
          ) : (
            <h2 className='text-white'>Start Your Project with Us</h2>
          )}
          <p>
            Whatever your goal or project size we will handle it utilize
            standards compliant. We hope you will be 100% satisfied.
          </p>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Review;
