import React, { useState } from "react";
import axios from "axios";
import base_url from '../../api/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  
  const [data_, setData] = useState({
      name: "", 
      emailAddress: "",
      message: ""
  });

  const [isLoading, setIsloading] = useState(false);

    // Clear form data
    const clear = () => {
      setData({
        name: "", 
        emailAddress: "",
        message: ""
      });
    }; 

    // Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const reqData = { 
      adminEmailAddress: "info@igeeksng.com",
      companyName: "iGeeksNG Solution",
      data:{
        name: data_.name, 
        emailAddress: data_.emailAddress,
        message: data_.message,
      } 
    };

    // config 
    const config = {
      headers: {
        'content-type': 'application/json'
    }
    }
    if (!reqData) {
      return toast.error('Please fill in the required details');
    }
   try {
    setIsloading(true);
    const  result  = await axios.post(`${base_url}/forms/form_02`,  reqData, config);
    setIsloading(false);
    toast.success(result.data.message);
    clear();
   } catch (error) {
    setIsloading(false);
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
   }
  };

// Handle input change
  const handleChange = (e) => {
    const newData = { ...data_ };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  return (
    <> 
      <ToastContainer position="bottom-center" limit={1} />
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>Contact Us</h2>
               
              </div>

              <form onSubmit={handleSubmit} className='register-form'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label htmlFor='name' className='mb-1'>
                     Name <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='name'
                        required
                        placeholder='Name'
                        aria-label='name'
                        value={data_.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='email' className='mb-1'>
                      Email<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='emailAddress'
                        required
                        placeholder='Email'
                        aria-label='Email'
                        value={data_.emailAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  
                  
                  <div className='col-12'>
                    <label htmlFor='yourMessage' className='mb-1'>
                      Message <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <textarea
                        className='form-control'
                        id='message'
                        required
                        placeholder='How can we help you?'
                        value={data_.message}
                        onChange={handleChange}
                        style={{ height: '120px' }}
                      ></textarea>
                    </div>
                  </div>
                 
                </div>
                
                <button style={{background:"#0b163f", color:"#fff"}} disabled={isLoading} type="submit" className='btn mt-4'>
              {isLoading ? 'Sending...' : ' Submit'}
            </button>

              </form>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/Contact-us.gif'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
