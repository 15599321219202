import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Fade from 'react-reveal/Fade';
const Feature = () => {
  return (
    <>
      <Fade bottom>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/unique-2.gif'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
                <div className='mb-32'>
                  <SectionTitle
                    title='We are expert in software product development'
                    description='We apply a systematic approach to all projects. 
                    Meanwhile, how we get things done depends on the needs. We use one or mix of the following best practices to understand and develop solutions that meets our clients needs'
                    leftAlign
                  />
                </div>
                <ul className='list-unstyled d-flex flex-wrap list-two-col'>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Product requirement
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Application development
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Application Testing
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Application deployment
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                     Application Maintenance
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Design Optimization
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Project evaluation
                  </li>
                  <li className='py-1'>
                    <i style={{color:"#ffb116"}} className='fas fa-check-circle me-2'></i>
                    Clinets Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Fade>
    </>
  );
};

export default Feature;
