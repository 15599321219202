import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgOne = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
          <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/expertise.jpg'
                    alt=''
                    className='img-fluid'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mt-4' data-aos='fade-up'>
                <SectionTitle
                  title='Technical Expertise'
                  description="The Project Management Institute's approach is applied to all phases of the software development life cycle at iGeeksNG. Certified Project Management Professionals construct checkpoints and gateways to keep our projects on track, on time, and on budget.
                  The professional services offered by iGeeksNG are meant to be just that. We aim for the greatest level of excellence in all we do, and we won't stop until we've achieved it. We've got what it takes to help you meet your objectives with a little panache, whether you require managed IT services like remote server administration, skilled cloud migration support, or want to radically reinvent your organization for the future."
                />
              </div>
              <ul
                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
              </ul>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgOne;
