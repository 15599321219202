import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
 import { ClientsData } from '../data';
 
SwiperCore.use([Navigation]);

const Clients = () => {
  const swiperOption = {
    slidesPerView: 4,
    mousewheel: true,
    spaceBetween: 2,
    slidesPerGroup: 2,
    loop: true,
    navigation: {
      nextEl: '.swiper-nav-control .swiper-button-next',
      prevEl: '.swiper-nav-control .swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 25,
      },
      1142: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };
  return (
    <>
      <section className='ptb-100'>
        <div className='container'>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='position-relative'>
                <Swiper {...swiperOption}>
                  {ClientsData.map((data) => (
                    <SwiperSlide key={data.id}>
                      <div
                        className='p-2 rounded-custom position-relative bg-custom-light border border-2'
                      >
                        
                        <div className='d-flex align-items-center'>
                          <img
                          style={{marginLeft:"60px"}}
                            src={data.clientImg}
                            className='img-fluid me-3 rounded img-fluid logo-white'
                            width='100'
                            alt='clients'
                          />
                          
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className='swiper-nav-control'>
                  <span className='swiper-button-next'></span>
                  <span className='swiper-button-prev'></span>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
