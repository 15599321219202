import React from 'react';

const Terms = () => {
  return (
    <>
      <section className='career-promo ptb-120 bg-light'>
        <div className='container'>
           
          
              <div className='single-feature d-flex mt-4 mt-xl-0 mt-lg-0 mt-md-0 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-scale-unbalanced fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Use of License</h5>
                  <p className='mb-0'>
                  Permission is granted to temporarily download one copy of the materials (information or software) on iGeeksNG’s website for personal, non- commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                  <br />
                    <br />
                    • modify or copy the materials;
                    <br />
                    <br />
                    • use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                    <br />
                     <br />
                    • attempt to decompile or reverse engineer any software contained on iGeeksNG’s website;
                    <br />
                    <br />
                    • remove any copyright or other proprietary notations from the materials; or
                    <br />
                    <br />
                     • transfer the materials to another person or “mirror” the materials on any other server.
                    <br />
                    <br />
                    This license shall automatically terminate if you violate any of these
                     restrictions and may be terminated by iGeeksNG at any time.
                      Upon terminating your viewing of these materials or upon 
                      the termination of this license, you must destroy any 
                      downloaded materials in your possession whether in electronic or printed format.
                  </p>
                </div>
              
        </div> 

        <div className='row'>
          
        <div className='col-md-6 col-lg-6'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-square-info fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Disclaimer</h5>
                  <p className='mb-0'>
                  We may collect, hold, use, and disclose information for the following purposes and personal information not be further processed in a manner that is incompatible with these purposes:
                  <br />
                    <br />
                  • To contact and communicate with you.
                  <br />
                    <br />
                  • For internal record keeping and administrative purposes.
                  <br />
                    <br />
                  • For analytics, market research, and business development, 
                  including to operate and improve our website, associated applications, 
                  and associated social media platforms.
                  <br />
                    <br />
                  • For advertising and marketing, including to send you promotional
                   information about our products and services and information about third 
                   parties that we consider may be of interest to you.
                  </p>
                </div>
              </div>
            </div>
           
           
            <div className='col-md-6 col-lg-6'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-person fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5> Limitations:</h5>
                  <h6>Disclosure of personal information to third parties</h6>
                  <p className='mb-0'>
                  We may disclose personal information to:
                  <br />
                    <br />
                  • Third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors, and payment systems operators.
                  <br />
                    <br />
                  • Third parties to collect and process data.
                  </p>
                </div>
              </div>
            </div>

            </div>
             
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-arrow-right-arrow-left fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Accuracy of Materials:</h5>
                  <h6>International transfers of personal information</h6>
                  <p className='mb-0'>
                  The personal information we collect is stored and processed in the United 
                  States, or where we or our partners, affiliates, and third-party providers
                   maintain facilities. By providing us with your personal information,
                    you consent to disclosure to these overseas third parties.
                     We will ensure that any transfer of personal information 
                     from countries in the European Economic Area (EEA) to countries
                      outside the EEA will be protected by appropriate safeguards,
                       for example by using standard data protection clauses approved 
                       by the European Commission, or the use of binding corporate rules or 
                       other legally accepted means.
                       <br />
                    <br />
                     Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek redress under our jurisdiction’s privacy laws.
                  </p>
                </div>
              </div>
           
     
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-gavel fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Links</h5>
                  <h5>Your rights and controlling your personal information</h5>
                  <p className='mb-0'>  
                    <br />
                  <h6>Choice and consent:</h6>
                    By providing personal information to us, you consent to us collecting, 
                    holding, using, and disclosing your personal information in accordance 
                    with this privacy policy. If you are under 16 years of age, you must have, 
                    and warrant to the extent permitted by law to us, that you have your parent 
                    or legal guardian’s permission to access and use the website and they
                     (your parents or guardian) have consented to you providing us with 
                     your personal information. You do not have to provide personal information 
                     to us; however, if you do not, it may affect your use of this website or
                      the products and/or services offered on or through it.
                      <br />
                      <br />
                    <h6>Information from third parties:</h6>
                    If we receive personal information about you from a third party, 
                    we will protect it as set out in this privacy policy. 
                    If you are a third party providing personal information 
                    about somebody else, you represent and warrant that you have 
                    such person’s consent to provide the personal information to us.
                    <br />
                  <br />
                  <h6>Restrict:</h6>
                You may choose to restrict the collection or use of your personal information. 
                If you have previously agreed to us using your personal 
                information for direct marketing purposes,
                 you may change your mind at any time by
                  contacting us using the details below.
                   If you ask us to restrict or limit how we 
                   process your personal information, we will let you know how 
                   the restriction affects your use of our website or products and services.
                   <br />
                  <br />
                  <h6>Access and data portability:</h6>
                  You may request a copy of the personal information we hold about you. 
                  Where possible, we will provide this information in CSV format or other 
                  easily readable machine format. You may request that we erase the personal 
                  information we hold about you at any time. You may also request that
                   we transfer this personal information to another third party. 
                   Please contact our Data Controller (info@igeeksng.com) with these requests.
                   <br />
                  <br />
                  <h6>Correction:</h6>
                    If you believe that any information we hold about you is inaccurate, 
                    out of date, incomplete, irrelevant, or misleading, please contact us 
                    using the details below. We will take reasonable steps to correct
                     any information found to be inaccurate, incomplete, misleading, or out of date.
                    Notification of data breaches:
                    We will comply with laws applicable to us with respect to any data breach.
                    <br />
                  <br />
                  <h6>Complaints:</h6>
                  If you believe that we have breached a relevant data protection law 
                  and wish to make a complaint, please contact us using the details
                   below and provide us with full details of the alleged breach. 
                   We will promptly investigate your complaint and respond to you, 
                   in writing, setting out the outcome of our investigation and the 
                   steps we will take to deal with your complaint. You also have the
                    right to contact a regulatory body or data protection authority in
                     relation to your complaint.
                     <br />
                     <br />
                    <h6>Unsubscribe:</h6>
                     To unsubscribe from our email database or opt out of communications
                      (including marketing communications), please contact us using the 
                      details below or opt out using the opt-out facilities provided in the communication.
                   <br />
                   <br />  
                  </p>
                </div>
              </div> 

              <div className='row'>

            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-cookie fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Modifications</h5>
                  <h6>Cookies:</h6>
                  <p className='mb-0'>
                  We use “cookies” to collect information about you and your activity across our site.
                   A cookie is a small piece of data that our website stores
                    on your computer, and accesses each time you visit, 
                    so we can understand how you use our site. 
                    This helps us serve you content based on
                     preferences you have specified. Please refer
                      to our Cookie Policy for more information.
                  <br />
                  </p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-cookie fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Governing Law</h5>
                  <h6>Business Transfers</h6>
                  <p className='mb-0'>
                  If we or our assets are acquired, or in the unlikely event that we go out of 
                  business or enter bankruptcy, we would include data among the assets
                   transferred to any parties who acquire us. You acknowledge that 
                   such transfers may occur, and that any parties who acquire us may 
                   continue to use your personal information according to this policy.
                  </p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-briefcase fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Business Transfer</h5>
                  <p className='mb-0'>
                  If we or our assets are acquired, or in the unlikely event that we go out of 
                  business or enter bankruptcy, we would include data among the assets 
                  transferred to any parties who acquire us. You acknowledge that
                   such transfers may occur, and that any parties who acquire us 
                   may continue to use your personal information according to this policy.
                  </p>
                </div>
              </div>
            </div>
           
            </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
