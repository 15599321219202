import React from 'react';
import Review from '../Review';
import RequestDemoForm from '../contact/RequestDemoForm';

const RequestForDemo = () => {
    return (
        <>
            <section className="sign-up-in-section bg-dark ptb-120 position-relative overflow-hidden">
                <div className="container">
                    <div className="row align-items-center justify-content-between"> 
                        <Review/>
                        <RequestDemoForm/>
                     </div>  
                </div> 
            </section>
        </>
    )
}

export default RequestForDemo
