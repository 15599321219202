import React from 'react';

const PrivacyHeader = () => {
  return (
    <>
      <section
        className='page-header position-relative overflow-hidden ptb-120 bg-dark'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-12'>
              <div className='text-center'>
                <h1 className='display-5 fw-bold'>Privacy Policy</h1>
                <p className='lead'>
                This policy is effective as of May 01, 2022. 
                </p>
              <p className='text-start'> If you have a question, request or want to report a privacy issue, please contact us at info@igeeksng.com. Your privacy is very important to us and it's iGeeksNG’s policy to respect your privacy regarding any information we may collect from you across our website, https://igeeksng.com</p>
            </div>
          </div>
          </div>
          <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5'></div>
        </div>
      </section>
    </>
  );
};

export default PrivacyHeader;
