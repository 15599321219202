import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import FeatureImgContentOne from '../../components/feature-img-content/FeatureImgContentOne';
import FeatureImgOne from '../../components/features/FeatureImgOne';
import FeatureImgFour from '../../components/features/FeatureImgFour';
import Footer from '../../layout/Footer/Footer';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Services = () => {
  return (
    <Layout>
      <Navbar classOption='navbar-light' />
      <PageHeader
        title='Grow your business with custom software development services'
        desc='Choosing the best software development partner is crucial to building a successful digital product. The right partner can apply the best tech solutions to build a secure, stable app that delivers exceptional user experience.'
      />
      <FeatureImgOne paddingTop />
      <FeatureImgContentOne />
      <FeatureImgFour />
      <Footer footerLight />
    </Layout>
  );
};

export default Services;
