import React from 'react';
import SectionTitle from '../common/SectionTitle';

const OurStory = () => {
  return (
    <>
      <section
        className='our-story-section pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat left bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-5 col-md-12 order-lg-1'>
              <div  className='section-heading sticky-sidebar'>
                <SectionTitle
                  subtitle='Building software for world changers'
                  title=''
                  description='iGeeksNG Solution is a software solution and IT consultancy company sculpting high-grade softwares 
                  on diverse platforms to clients ranging from the giant brands to young start-ups. We are a 
                  progressive and energetic software solutions company with a drive for achieving excellence whilst 
                  delivering utmost satisfaction to our clients.'
                />
                {/*  */}
                <h4>Our expertise covers:</h4>
                <p>Healthcare, Finance, Education, Logistic, Social Network, Retail,fintech, govtech  industries with Headquartered in the US with solid presence in Africa. 50+ Domain expert</p>
              </div>
              
            </div>
            <div className='col-lg-6 col-md-12 order-lg-0'>
              <div className='story-grid-wrapper position-relative'>
                {/* <!--animated shape start--> */}
                <ul className='position-absolute animate-element parallax-element shape-service z--1'>
                  <li className='layer' data-depth='0.02'>
                    <img
                      src='assets/img/color-shape/image-2.svg'
                      alt='shape'
                      className='img-fluid position-absolute color-shape-2 z-5'
                    />
                  </li>
                  <li className='layer' data-depth='0.03'>
                    <img
                      src='assets/img/color-shape/feature-3.svg'
                      alt='shape'
                      className='img-fluid position-absolute color-shape-3'
                    />
                  </li>
                </ul>
                {/* <!--animated shape end--> */}
                <div className='story-grid rounded-custom overflow-hidden position-relative'>
                  <div className='story-item bg-light border'>
                    <h3 className='display-5 fw-bold mb-1 text-warning'>
                      10+ Years
                    </h3>
                    <h6 className='mb-0'>In Business</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurStory;
