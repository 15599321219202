import React from 'react';

const FeatureImgContentOne = () => {
  return (
    <>
      <section className='feature-section pt-60 pb-120'>
        <div className='container'>
          
          <div className='row align-items-lg-center justify-content-between'>
          <div className='col-lg-6 order-lg-2'>
              <div className='pr-lg-4 position-relative'>
                <div className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'>
                  <img 
                    src='assets/img/cloud-infra.jpg'
                    alt=''
                    className='img-fluid rounded-custom shadow-sm'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right'></div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 order-lg-1 mb-7 mb-lg-0'>
              <div className='mt-4'>
                <h2>Infrastructure Engineering and maintenance</h2>
                <p>
                Maintain continuous availability of your business by utilizing flexible digital infrastructure and services to create tailored experiences.
                </p>
              </div>
              <ul className='list-unstyled d-flex flex-wrap mt-5'>
                <li>
                  <span>
                    <i style={{color:"#ffb116", fontSize:35}} className='fa-solid fa-laptop-arrow-down mb-4'></i>
                  </span>
                  <h3 className='h5'>Experience Services</h3>
                  <p>
                  Increase the number of experiences at a large scale and take advantage of everything ServiceNow has to offer, you'll need a new, 
                  strategic, and skilled approach to business transformation. iGeeksNG's
                   consulting approach enables you to build digital processes based on the Now
                    platform's strength and flexibility, and to scale them throughout
                     your whole business. We will continue to create great business
                      outcomes thanks to our cooperation and work with ServiceNow.
                       We develop digital workflows that alter processes and offer
                        the experiences that workers and consumers demand by leveraging
                         the power of the Now platform.
                  </p>
                </li>
                <li>
                  <span>
                    <i style={{color:"#ffb116", fontSize:35}} className='fal fa-solid fa-server mb-4'></i>
                  </span>
                  <h3 className='h5'>Edge Services</h3>
                  <p>
                  Delivering customer experiences with the growing demand for real-time data collection, 
                  processing, and consumption at the enterprise's edge, top businesses are developing 
                  edge-to-core infrastructure services from the outside in. Edge Services is a division 
                  of iGeeksNG that provides managed edge-to-core computing and associated industry 
                  solutions, services, and platforms tailored to the intricacies and size of the sector.
                  </p>
                </li>

                <li>
                  <span>
                    <i style={{color:"#ffb116", fontSize:35}} className='fal fa-solid fa-cloud-arrow-down mb-4'></i>
                  </span>
                  <h3 className='h5'>Cloud Services </h3>
                  <p>
                  Accelerate digital journey through iGeeksNG Cloud solution, includes 
                  a wide range of services for designing, building, migrating, and 
                  operating private and public cloud systems. By transitioning historical 
                  IT into a future-ready as-a-service delivery model, it lays the groundwork
                   for digital-ready operations. It also allows you to select the best-fit
                    alternative for your specific needs.
                  </p>
                </li>
              </ul>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentOne;
