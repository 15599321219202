import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Footer from '../../layout/Footer/Footer';
import Privacy from '../../components/PrivacyPolicy/Privacy';
import PrivacyHeader from '../../components/PrivacyPolicy/PrivacyHeader'; 

const PrivacyPolicy = () => {
  return (
    <Layout>
      <PageMeta title='iGeeksNG - IT Solution Provider' />
      <Navbar /> 
      <PrivacyHeader/>
      <Privacy />
      <Footer footerLight />
    </Layout>
  );
};

export default PrivacyPolicy;
