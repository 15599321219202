import React from 'react';
import PageMeta from '../components/common/PageMeta';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import Terms from '../components/TermsOfUse/Terms';
import TermsHeader from '../components/TermsOfUse/TermsOfUseHeader'; 

const TermsOfUse = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar /> 
      <TermsHeader/> 
      <Terms />
      <Footer
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default TermsOfUse;
