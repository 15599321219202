import React, { useState, useEffect} from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import base_url from '../../api/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const RequestDemoForm = () => {
  const [data, setData] = useState({
    name: "",
        emailAddress: "",
        workEmailAddress: "",
        website: "",
        budget: "",
        country: "",
        message: "",
        notificationOptIn: false,
        
  });
  const [notificationOptIn, setnotificationOptIn] = useState(false);
  const [isLoading, setIsloading] = useState(false);
 
    // Clear form data
    const clear = () => {
      setData({
        name: "",
        emailAddress: "",
        workEmailAddress: "",
        budget: "",
        country: "",
        message: "",
        notificationOptIn: false,
        website: "",
      });
    }; 

  // Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqData = {
      adminEmailAddress: "info@igeeksng.com",
      companyName: "iGeeksNG Solution",
      data:{
        name: data.name,
        emailAddress: data.emailAddress,
        companyEmail: data.workEmailAddress,
        budget: data.budget,
        country: data.country,
        message: data.message,
         notificationOptIn: notificationOptIn,
         companyWebsite: data.website
      }
    };
 
    if (!reqData) {
      return toast.error('Please fill in the required details');
    }

    try {
      setIsloading(true);
      const data = await axios.post(`${base_url}/forms/form_04`, reqData)
        setIsloading(false);
        toast.success(data.data.message);
        clear();
    } catch (error) {
      setIsloading(false);
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : 'Unable to connect! Kindly try again later.'
      );
    }   
  };

  const [countries, setCountries] = useState();

  // Load countries
  useEffect(() => { 
    setIsloading(true);
    axios
      .get(`${base_url}/resources/country_states_cities`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch(() =>{ 
        setCountries(['United States','Nigeria', 'Canada', 'United Kingdom']);
      } )
      .finally(() => {
        setIsloading(false);
      });
  }, []);

 

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };


  const handleOnCheck = () => {
    setnotificationOptIn(!notificationOptIn);
  };


  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>
          <form onSubmit={handleSubmit} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                   id='name'
                   value={data.name}
                   onChange={handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Name'
                    aria-label='name'
                  />
                </div>
              </div>
              <div className='col-sm-6 '>
                <div className='input-group mb-3'>
                  <input
                   id='emailAddress'
                   value={data.emailAddress}
                   onChange={handleChange}
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    aria-label='email'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                   id='website'
                   value={data.website}
                   onChange={handleChange}
                    type='text'
                    className='form-control'
                    placeholder='Company website'
                    aria-label='company-website'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                  id='workEmailAddress'
                  alue={data.workEmailAddress}
                  onChange={handleChange}
                    type='email'
                    className='form-control'
                    placeholder='Work email'
                    aria-label='work-Email'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>

                  <select
                      id='budget'
                      value={data.budget}
                      onChange={handleChange}
                    className='form-control form-select'
                    name='budget'
                    required=''
                    data-msg='Please select your budget.'
                  >
                    <option>Budget</option>
                    <option value="None, just getting started">None, just getting started</option>
                    <option  value="Less than $10,000">Less than $10,000</option>
                    <option value="$10,000 to $30,000">$10,000 to $30,000</option>
                    <option value="$30,000 to $70,000">$30,000 to $70,000</option>
                    <option value="$70,000 to $100,000">$70,000 to $100,000</option>
                    <option value="More than $100,000">More than $100,000</option>
                  </select>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                   
<select
                   name='country' 
                   id='country' className='form-control form-select'
                   value={data.country}
                   onChange={handleChange} 
                > 
                  {countries
                    ? countries.map((country) => {
                        return (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        );
                      })
                    : null}
                </select> 
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    id='message'
                    value={data.message}
                    onChange={handleChange}
                    placeholder='Tell us more about your project, needs and budget'
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    value={notificationOptIn}
                   id='notificationOptIn'
                   onChange={handleOnCheck}
                    className='form-check-input'
                    type='checkbox'
                    checked={notificationOptIn}
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    Yes, I'd like to receive occasional marketing emails from
                    us. I have the right to opt out at any time.
                    <Link to='privacy-policy'> View privacy policy</Link>.
                  </label>
                </div>
              </div>
              <div className='col-12'>
              <button  style={{background:"#0b163f", color:"#fff"}}disabled={isLoading} type="submit" className='btn mt-4'>
              {isLoading ? 'Sending...' : ' Submit'}
            </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RequestDemoForm;