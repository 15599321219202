import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Services from '../pages/services/Services';
import SingleService from '../pages/services/SingleService';
import NotFoundScreen from '../components/others/NotFoundScreen';
import ScrollToTop from '../components/common/ScrollToTop';
import Home from '../Home/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import RequestDemo from '../pages/RequestDemo';
import TermsOfUse from '../pages/TermsOfUse';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';

const Routes = () => {
  return (
    <>
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about-us' component={About} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/single-service' component={SingleService} />
        <Route exact path='/contact-us' component={Contact} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/terms-of-use' component={TermsOfUse} />
        <Route exact path='/book-meeting' component={RequestDemo} />
        <Route exact path='/career' component={PrivacyPolicy} />
        <Route component={NotFoundScreen} />
      </Switch>
    </Router>
  </>
  );
}

export default Routes;

