import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactForm from '../components/contact/ContactForm';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <PageMeta title='iGeeksNG IT Solutions' />
      <Navbar />
      <PageHeader
        title='Contact Us'
        desc='We can’t wait to hear what you are dreaming of...'
      />
      <ContactBox />
      <ContactForm />
      <Footer
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Contact;
