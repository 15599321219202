import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const CallToAction = () => {
  return (
    <>
      <section className='cta-subscribe bg-dark ptb-120 position-relative overflow-hidden'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>
                <SectionTitle
                  subtitle="We are a Hi away!"
                  title='Get in touch with us to develop your next digital product(s).'
                  description=''
                  dark
                />
                <div className='form-block-banner mw-60 m-auto mt-5'>
                  <Link style={{background:"#ffb116", color:"#fff"}} to='/book-meeting' className='btn me-5'>
                   Book A Meeting
                  </Link>
                  <Link style={{background:"#ffb116", color:"#fff"}} to='/contact-us' className='btn'>
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;
