import React from 'react';
import PageMeta from '../components/common/PageMeta';
import Feature from '../components/features/Feature';
import FeatureOne from '../components/features/FeatureOne';
import Hero from '../components/Hero/Hero';
import CallToAction from '../components/CallToAction/CallToAction';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';


const Home = () => {
  return (
    <Layout extraClass='overflow-hidden'>
      <PageMeta title='iGeeksNG Solution - Software &amp; IT Solutions Provider' />
      <Navbar />
      <Hero />
      <WhatWeDo />
      <CallToAction />
      <FeatureOne />
      <Feature />
      <Footer
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Home;
