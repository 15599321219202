import React from 'react';

const Privacy = () => {
  return (
    <>
      <section className='career-promo ptb-120 bg-light'>
        <div className='container'>
            
            
              <div className='single-feature d-flex p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-circle-info fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Information we collect</h5>
                  <h6>Log data:</h6>
                  <p className='mb-0'>
                  When you visit our website, our servers may automatically log 
                  the standard data provided by your web browser. It may include 
                  your computer’s Internet Protocol (IP) address, your browser type and 
                  version, the pages you visit, the time and date of your visit, the time 
                  spent on each page, and other details.{' '}
                  </p>
                  <br/>
                  <h6>Personal information:</h6>
                <p>We may ask for personal information, such as your name, 
                  title, company, email, phone number, and work address.</p>
                </div>
                </div> 
    
          
 
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-scale-unbalanced fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Legal bases for processing</h5>
                  <h6>Legal Process:</h6>
                  <p className='mb-0'>
                  We will process your personal information lawfully, fairly, 
                  and in a transparent manner. We collect and process information about you only where we have legal bases for doing so. These legal bases depend on the services you 
                  use and how you use them, meaning we collect and use your information only where: <br /><br />
                  • It is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);
                  <br />
                  <br />
                  • It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests; or
                  <br />
                  <br />
                 • You give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or we need to process your data to comply with a legal obligation.

                 Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).
                 <br />
                 <br />
                 We don’t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. 
                 <br />
                 <br />
                 That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.
                  </p>
                </div>
              </div> 

<div className='row'>
        <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-square-info fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Collection and use of information</h5>
                  <h6>Use of Information:</h6>
                  <p className='mb-0'>
                  We may collect, hold, use, and disclose information for the 
                  following purposes and personal information not be further 
                  processed in a manner that is incompatible with these purposes:
                  <br />
                  <br />
                  • To contact and communicate with you;
                  <br />
                  <br />
                  • For internal record keeping and administrative purposes;
                  <br />
                  <br />
                  • For analytics, market research, and business development, 
                  including to operate and improve our website, 
                  associated applications, and associated social media platforms.
                  <br />
                  <br />
                  • For advertising and marketing, including to send you promotional information 
                  about our products and services and information about third parties that we
                   consider may be of interest to you.
                   <br />
                  <br />
                  </p>
                </div>
              </div>
            </div>
            

            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-person fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Personal Information</h5>
                  <h6>Disclosure of personal information to third parties</h6>
                  <p className='mb-0'>
                  We may disclose personal information to:
                  <br />
                  <br />
                  • Third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors, and payment systems operators; and
                  <br />
                  <br />
                  • Third parties to collect and process data.
                  </p>
                </div>
              </div>
            </div>
           
            </div>
           
           
<div className='row'>
  
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'> 
                <span style={{color:"#ffb116"}} className='fa-solid fa-arrow-right-arrow-left fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Transfer of Information</h5>
                  <h6>International transfers of personal information</h6>
                  <p className='mb-0'>
                  The personal information we collect is stored and processed in the United States, 
                  or where we or our partners, affiliates, and third-party providers maintain facilities. 
                  By providing us with your personal information, you consent to disclosure
                   to these overseas third parties. We will ensure that any transfer of personal 
                   information from countries in the European Economic Area (EEA) 
                   to countries outside the EEA will be protected by appropriate safeguards,
                    for example by using standard data protection clauses approved
                     by the European Commission, or the use of binding corporate 
                     rules or other legally accepted means.
                  <br />
                  <br />
                  • Where we transfer personal information from a non-EEA country to another
                   country, you acknowledge that third parties in other jurisdictions may not 
                   be subject to similar data protection laws to the ones in our jurisdiction. 
                   There are risks if any such third party engages in any act or practice that
                    would contravene the data privacy laws in our jurisdiction and this might 
                    mean that you will not be able to seek redress under our jurisdiction’s privacy laws.
                  </p>
                </div>
              </div>
            </div>
    
      <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-gavel fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Rights and Control</h5>
                  <h6>Your rights and controlling your personal information</h6>
                  <p className='mb-0'>
                  By providing personal information to us, you consent to us collecting, holding,
                   using, and disclosing your personal information in accordance
                    with this privacy policy. If you are under 16 years of age, 
                    you must have, and warrant to the extent permitted by law to us, 
                    that you have your parent or legal guardian’s permission to access 
                    and use the website and they (your parents or guardian) have consented 
                    to you providing us with your personal information. You do not have to 
                    provide personal information to us; however, if you do not, 
                    it may affect your use of this website or the products and/or 
                    services offered on or through it.
                    <br />
                  <br />
                  • Third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors, and payment systems operators.
                  <br />
                  <br />
                  </p>
                </div>
              </div>
            </div>
            
</div>

 
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-square-info fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Information from third parties:</h5>
                  <p className='mb-0'>
                  If we receive personal information about you from a third party, 
                  we will protect it as set out in this privacy policy. 
                  If you are a third party providing personal information
                   about somebody else, you represent and warrant that
                    you have such person’s consent to provide the personal information to us.
                 
                  </p> <br />
                  <h6>Restrict:</h6>   
                 <p> You may choose to restrict the collection or use of your personal information.
                  If you have previously agreed to us using your personal information 
                  for direct marketing purposes, you may change your mind at any time
                   by contacting us using the details below. If you ask us to restrict 
                   or limit how we process your personal information, we will let you
                    know how the restriction affects your use of our website or products and services.</p> 
                    <h6>Access and data portability:</h6>
                   <p>You may request a copy of the personal information we hold about you. 
                   Where possible, we will provide this information in CSV format or other 
                   easily readable machine format. You may request that we erase the
                    personal information we hold about you at any time. You may also
                     request that we transfer this personal information to another third party.
                     Please contact our Data Controller (info@igeeksng.com) with these requests.</p>
                   <h6>Correction:</h6>
                   <p>If you believe that any information we hold about you is inaccurate, 
                   out of date, incomplete, irrelevant, or misleading, please contact 
                   us using the details below. We will take reasonable steps 
                   to correct any information found to be inaccurate, 
                   incomplete, misleading, or out of date.</p>
                   <h6> Notification of data breaches:</h6>
                
                <p> We will comply with laws applicable to us with respect to any data breach.</p>
                 <h6> Complaints:</h6>
              <p> If you believe that we have breached a relevant data protection law and wish
                to make a complaint, please contact us using the details below and provide
                 us with full details of the alleged breach. We will promptly investigate 
                 your complaint and respond to you, in writing, setting out the outcome of
                  our investigation and the steps we will take to deal with your complaint. 
                  You also have the right to contact a regulatory body or data protection 
                  authority in relation to your complaint.</p>
                  <h6>Unsubscribe:</h6>
                  <p>To unsubscribe from our email database or opt out of communications (including marketing communications), please contact us using the details below or opt out using the opt-out facilities provided in the communication.</p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-cookie fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Cookies</h5>
                  <p  >
                  We use “cookies” to collect information about you and your activity across 
                  our site. A cookie is a small piece of data that our website stores
                   on your computer, and accesses each time you visit, 
                   so we can understand how you use our site. 
                   This helps us serve you content based on preferences you have specified.
                    Please refer to our Cookie Policy for more information.
                  </p>
                </div>
              </div>
            </div> 
 
<div className='row'>
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-briefcase fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Business Transfer</h5>
                  <p  >
                  If we or our assets are acquired, or in the unlikely event that we go out of 
                  business or enter bankruptcy, we would include data among the assets 
                  transferred to any parties who acquire us. You acknowledge that
                   such transfers may occur, and that any parties who acquire us 
                   may continue to use your personal information according to this policy.
                  </p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='single-feature d-flex mt-4 p-5 bg-white rounded-custom'>
                <span style={{color:"#ffb116"}} className='fa-solid fa-book-section fa-2x'></span>
                <div className='ms-4 mt-2'>
                  <h5>Changes to Policy</h5>
                  <p>
                  At our discretion, we may change our privacy policy to reflect current 
                  acceptable practices. We will take reasonable steps to let users know 
                  about changes via our website. Your continued use of this site after any
                   changes to this policy will be regarded as acceptance of our practices 
                   around privacy and personal information. If we make a significant change
                    to this privacy policy, for example changing a lawful basis on which
                     we process your personal information, we will ask you to re-consent 
                     to the amended privacy policy.
                  </p>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
