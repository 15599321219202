import React from 'react';
import { Link } from 'react-router-dom';

const AboutPageHero = () => {
  return (
    <>
      <section
        className='about-header-section ptb-120 position-relative overflow-hidden bg-dark'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat center right",
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='section-heading-wrap d-flex justify-content-between z-5 position-relative'>
                <div className='about-content-left'>
                  <div className='about-info mb-5'>
                    <h1 className='fw-bold display-5'>
                     Who We Are?
                    </h1>
                    <p className='lead'>
                    We are innovative technology solutions development company  on a mission to bring your digital products & ideas to live.
                 
                    </p>
                    <Link style={{background:"#ffb116", color:"#fff"}} to='/book-meeting' className='btn mt-4 me-3'>
                      Book A Meeting
                    </Link>
                   
                  </div>
                  <img
                    src='assets/img/software-dev.png'
                    alt='about'
                    className='img-fluid about-img-first mt-5 rounded-custom shadow'
                  />
                </div>
                <div className='about-content-right'>
                  <img
                    src='assets/img/brainstorming.png'
                    alt='about'
                    className='img-fluid mb-5 rounded-custom shadow'
                  />
                  <img
                    src='assets/img/about-img.png'
                    alt='about'
                    className='rounded-custom about-img-last shadow'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5'></div>
      </section>
    </>
  );
};

export default AboutPageHero;
